<template>
  <div>
    <CForm @submit="submit" v-if="loaded">
      <!--Disable browser autocomplete-->
      <div style="display: none;">
        <input type="text" autocomplete="new-password">
        <input type="password" autocomplete="new-password">
      </div>
      <!--/Disable browser autocomplete-->
      <CCard>
        <CCardBody>

          <CAlert color="success text-center" v-if="!_.isEmpty(sentMessage)" @update:show="sentMessage = ''">
            {{ sentMessage }}
          </CAlert>

          <div style="display: flex; justify-content: end;">
            <div :class="{ 'text-success': !customer.blocked, 'text-muted': customer.blocked }" class="text-bold" style="font-size: 12px;">{{ customer.blocked ? $t('disabled') : $t('enabled') }}</div>
            &nbsp;
            <CSwitch
                class="mr-1"
                color="success"
                :checked="!customer.blocked"
                variant="opposite"
                size="sm"
                @update:checked="toggleBlock"
            />
          </div>

          <div class="row">
            <div class="col-md-6 col-sm-12">
              <CInput
                  :label="$t('name')"
                  type="text"
                  v-model="customer.full_name"
                  @keydown="resetErrors('full_name')"
                  :invalid-feedback="_.has(errors, 'full_name') ? errors.full_name : ''"
                  :is-valid="_.has(errors, 'full_name') ? false : null"
                  ref="full_name"
              >
              </CInput>
            </div>

            <div class="col-md-6 col-sm-12">
              <CInput
                  :label="$t('short_name')"
                  type="text"
                  v-model="customer.short_name"
                  @keydown="resetErrors('short_name')"
                  :invalid-feedback="_.has(errors, 'short_name') ? errors.short_name : ''"
                  :is-valid="_.has(errors, 'short_name') ? false : null"
                  ref="short_name"
              >
              </CInput>
            </div>

            <div class="col-md-6 col-sm-12">
              <CInput
                  :label="$t('email')"
                  type="text"
                  v-model="customer.email"
                  @keydown="resetErrors('email')"
                  :invalid-feedback="_.has(errors, 'email') ? errors.email : ''"
                  :is-valid="_.has(errors, 'email') ? false : null"
              >
              </CInput>
            </div>

            <div class="col-md-6 col-sm-12">
              <CSelect
                  :label="$t('customer_type')"
                  :value.sync="customer.type"
                  :options="[
                      {
                        value: 'individual',
                        label: $t('individual'),
                      },
                      {
                        value: 'establishment',
                        label: $t('establishment'),
                      }
                  ]"
                  :placeholder="$t('please_select_a_customer_type')"
                  @click="resetErrors('type')"
                  :invalid-feedback="_.has(errors, 'type') ? errors.type : ''"
                  :is-valid="_.has(errors, 'type') ? false : null"
              />
            </div>

            <div class="col-md-6 col-sm-12" v-show="customer.type === 'establishment'">
              <CInput
                  :label="$t('commercial_register_number')"
                  type="text"
                  v-model="customer.commercial_register_number"
                  @keydown="resetErrors('commercial_register_number')"
                  :invalid-feedback="_.has(errors, 'commercial_register_number') ? errors.commercial_register_number : ''"
                  :is-valid="_.has(errors, 'commercial_register_number') ? false : null"
              >
              </CInput>
            </div>

            <div class="col-md-6 col-sm-12" v-show="customer.type === 'establishment'">
              <CInput
                  :label="$t('vat_registration_number')"
                  type="text"
                  v-model="customer.vat_registration_number"
                  @keydown="resetErrors('vat_registration_number')"
                  :invalid-feedback="_.has(errors, 'vat_registration_number') ? errors.vat_registration_number : ''"
                  :is-valid="_.has(errors, 'vat_registration_number') ? false : null"
              >
              </CInput>
            </div>
          </div>

          <div class="wy-customer-address-block">
            <div class="wy-customer-address-block-title">{{ $t('address_information') }}</div>
            <div class="row">

              <div class="col-md-6 col-sm-12">
                <CInput
                    :label="$t('building_number')"
                    type="text"
                    v-model="customer.building_number"
                    @keydown="resetErrors('building_number')"
                    :invalid-feedback="_.has(errors, 'building_number') ? errors.building_number : ''"
                    :is-valid="_.has(errors, 'building_number') ? false : null"
                >
                </CInput>
              </div>

              <div class="col-md-6 col-sm-12">
                <CInput
                    :label="$t('street')"
                    type="text"
                    v-model="customer.street"
                    @keydown="resetErrors('street')"
                    :invalid-feedback="_.has(errors, 'street') ? errors.street : ''"
                    :is-valid="_.has(errors, 'street') ? false : null"
                >
                </CInput>
              </div>

              <div class="col-md-6 col-sm-12">
                <CInput
                    :label="$t('district')"
                    type="text"
                    v-model="customer.district"
                    @keydown="resetErrors('district')"
                    :invalid-feedback="_.has(errors, 'district') ? errors.district : ''"
                    :is-valid="_.has(errors, 'district') ? false : null"
                >
                </CInput>
              </div>

              <div class="col-md-6 col-sm-12">
                <CInput
                    :label="$t('city')"
                    type="text"
                    v-model="customer.city"
                    @keydown="resetErrors('city')"
                    :invalid-feedback="_.has(errors, 'city') ? errors.city : ''"
                    :is-valid="_.has(errors, 'city') ? false : null"
                >
                </CInput>
              </div>

              <div class="col-md-6 col-sm-12">
                <CInput
                    :label="$t('zip_code')"
                    type="text"
                    v-model="customer.zip_code"
                    @keydown="resetErrors('zip_code')"
                    :invalid-feedback="_.has(errors, 'zip_code') ? errors.zip_code : ''"
                    :is-valid="_.has(errors, 'zip_code') ? false : null"
                >
                </CInput>
              </div>

              <div class="col-md-6 col-sm-12">
                <CInput
                    :label="$t('address_secondary_number')"
                    type="text"
                    v-model="customer.address_secondary_number"
                    @keydown="resetErrors('address_secondary_number')"
                    :invalid-feedback="_.has(errors, 'address_secondary_number') ? errors.address_secondary_number : ''"
                    :is-valid="_.has(errors, 'address_secondary_number') ? false : null"
                >
                </CInput>
              </div>

              <div class="col-md-6 col-sm-12">
                <CInput
                    :label="$t('short_address')"
                    type="text"
                    v-model="customer.short_address"
                    @keydown="resetErrors('short_address')"
                    :invalid-feedback="_.has(errors, 'short_address') ? errors.short_address : ''"
                    :is-valid="_.has(errors, 'short_address') ? false : null"
                >
                </CInput>
              </div>

              <div class="col-md-6 col-sm-12">
                <CInput
                    :label="$t('gps_location_url')"
                    type="text"
                    v-model="customer.gps_location_url"
                    @keydown="resetErrors('gps_location_url')"
                    :invalid-feedback="_.has(errors, 'gps_location_url') ? errors.gps_location_url : ''"
                    :is-valid="_.has(errors, 'gps_location_url') ? false : null"
                >
                  <template #append>
                    <a v-show="!_.isEmpty(customer.gps_location_url) && validURL(customer.gps_location_url)"
                       :href="customer.gps_location_url"
                       target="_blank"
                       class="btn btn-red text-white">
                      <span class="wy-icon-gps-location" style="margin: 0; font-size: 22px;"></span>
                    </a>
                  </template>
                </CInput>
              </div>

            </div>
          </div>

          <div class="wy-customer-phones-block">
            <div class="wy-customer-phones-block-title">{{ $t('phones') }}</div>
            <span class="wy-customer-phones-block-add" @click="addPhone">
              <i class="wy-icon-add p-0"></i>
            </span>
            <table class="table table-sm" v-if="!this._.isEmpty(customer.phones)">
              <thead>
                <tr>
                  <th style="width: 40%;">{{ $t('name') }}</th>
                  <th>{{ $t('number') }}</th>
                  <th style="width: 20px;">&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(phone, phoneIndex) in customer.phones" :key="`phone_${phoneIndex}`">
                <td>
                  <CInput
                      type="text"
                      v-model="customer.phones[phoneIndex].name"
                      @keydown="resetErrors(`phones.${phoneIndex}.name`)"
                      :invalid-feedback="_.has(errors, `phones.${phoneIndex}.name`) ? errors[`phones.${phoneIndex}.name`] : ''"
                      :is-valid="_.has(errors, `phones.${phoneIndex}.name`) ? false : null"
                  >
                  </CInput>
                </td>
                <td>
                  <CInput
                      type="text"
                      class="input-ltr-direction"
                      v-model="customer.phones[phoneIndex].number"
                      @keydown="resetErrors(`phones.${phoneIndex}.number`)"
                      :invalid-feedback="_.has(errors, `phones.${phoneIndex}.number`) ? errors[`phones.${phoneIndex}.number`] : ''"
                      :is-valid="_.has(errors, `phones.${phoneIndex}.number`) ? false : null"
                  >
                  </CInput>
                </td>
                <td style="padding-top: 12px;">
                  <CButton color="wy-action-button p-0" @click="deletePhone(phoneIndex)"><i class="wy-icon-delete p-0"></i></CButton>
                </td>
              </tr>
              </tbody>
            </table>
            <div v-else>
              <CAlert color="success" class="mt-1 text-center" v-if="!_.has(errors, 'phones')">
                {{ $t('nothing') }}
              </CAlert>
              <CAlert color="danger" class="mt-1 text-center is-invalid" v-else>
                {{ errors.phones }}
              </CAlert>
            </div>
          </div>

        </CCardBody>
        <CCardFooter>
          <div class="row">
            <div class="col">
              <CButton type="submit" size="sm" color="red" class="px-4">
                <CIcon name="cil-check-circle"/>
                {{ $t('save') }}
              </CButton>
            </div>
            <div class="col text-right">
<!--              <CButton type="reset" size="sm" color="secondary" class="px-4">-->
<!--                <CIcon name="cil-ban"/>-->
<!--                {{ $t('reset') }}-->
<!--              </CButton>-->
            </div>
          </div>
        </CCardFooter>
      </CCard>
    </CForm>
  </div>
</template>

<script>
import {getCustomer, getEmptyCustomer, insertCustomer, updateCustomer} from "../../../api/customer";
import scrollToFirstClass from '../../../mixins/DashboardScrollToFirstClass';

export default {
  name: 'Customer',
  mixins: [
    scrollToFirstClass,
  ],
  data() {
    return {
      passwordIsVisible: false,
      passwordIsVisibleConfirmation: false,
      errors: {},
      error: '',
      sentMessage: '',
      //
      id: '',
      customer: {},
      //
      isEdit: false,
      loaded: false,
    };
  },
  mounted() {
    this.tryGetCustomer();
  },
  methods: {
    resetErrors(property) {
      this.sentMessage = '';
      if (!this._.isUndefined(property)) {
        delete this.errors[property];
      } else {
        this.errors = {};
        this.error = ''
      }
    },
    tryGetCustomer() {
      if (this._.has(this.$route.params, 'customer_id') && /^\d+$/.test(this.$route.params.customer_id)) {
        this.isEdit = true;
        getCustomer(this, this.$route.params.customer_id, (response) => {
          this.customer = response.customer;
          this.loaded = true;
        }, (error) => {
          this.error = error.message;
        });
      } else {
        getEmptyCustomer(this, (response) => {
          this.customer = response.customer;
          this.loaded = true;
        }, (error) => {
          this.error = error.message;
        });
      }
    },
    validate(callback) {
      let hasErrors = false;
      this.resetErrors();

      this.$forceUpdate();
      if (!hasErrors) {
        callback()
      }
    },
    submit(event) {
      if (this._.isFunction(event.preventDefault)) {
        event.preventDefault();
      }
      this.sentMessage = '';
      this.validate(() => {
        if (!this.isEdit) {
          insertCustomer(this, this.customer, (response) => {
            this.sentMessage = response.message;
            this.$router.push({name: 'Customers'});
          }, (error) => {
            if (this._.has(error, 'errors')) {
              let i = 1;
              this._.forEach(error.errors, (item, key) => {
                this.errors[key] = item[0];
                if (i === this._.size(error.errors)) {
                  this.$forceUpdate();
                  this.scrollToFirstClass('is-invalid');
                }
                i += 1;
              });
            }
          });
        } else {
          updateCustomer(this, this.customer, (response) => {
            this.sentMessage = response.message;
            this.$router.push({name: 'Customers'});
          }, (error) => {
            if (this._.has(error, 'errors')) {
              let i = 1;
              this._.forEach(error.errors, (item, key) => {
                this.errors[key] = item[0];
                if (i === this._.size(error.errors)) {
                  this.$forceUpdate();
                  this.scrollToFirstClass('is-invalid');
                }
                i += 1;
              });
            }
          });
        }
      });
    },
    toggleBlock() {
      this.customer.blocked = !this.customer.blocked;
    },
    validURL(url) {
      const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))|'+ // OR ip (v4) address
          'localhost'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
      return !!pattern.test(url);
    },
    deletePhone(index) {
      this.$store.commit('confirmation', {
        message: this.$t('are_you_sure_that_you_want_to_delete_this_phone', {
          name: this.customer.phones[index].name,
          number: this.customer.phones[index].number,
        }),
        onOk: () => {
          this.resetErrors();
          this.customer.phones.splice(index, 1);
          this.$forceUpdate();
        }
      });
    },
    addPhone() {
      this.customer.phones.push({
        name: '',
        number: '',
        id: 0,
      })
    },
  },
}
</script>

<style lang="scss">
.wy-customer-address-block {
  background-color: #EEE;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  padding: 10px;

  .wy-customer-address-block-title {
    background-color: #FFFFFF;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    padding: 5px 15px;
    display: inline-block;
    margin: 0 0 10px 0;
  }
}
.wy-customer-phones-block {
  margin: 20px 0 0 0;
  background-color: #EEE;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  padding: 10px;
  position: relative;

  .wy-customer-phones-block-title {
    background-color: #FFFFFF;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    padding: 5px 15px;
    display: inline-block;
    margin: 0 0 10px 0;
  }

  .table thead th {
    border-bottom-width: 0!important;
    border-top-width: 0!important;
  }
  .table tbody td {
    border-bottom-width: 0!important;
    border-top-width: 0!important;
  }

  .wy-customer-phones-block-add {
    position: absolute;
    top: 10px;
    background-color: #FFFFFF;
    -webkit-border-radius: 90px;
    -moz-border-radius: 90px;
    border-radius: 90px;
    padding: 8px;
    cursor: pointer;
    [dir=ltr] & {
      right: 10px;
    }
    [dir=rtl] & {
      left: 10px;
    }
    transition: all 350ms ease;
    &:hover {
      background-color: #3462A5;
      i {
        color: #FFFFFF;
      }
    }
    i {
      transition: all 350ms ease;
      font-size: 20px;
    }
  }
}
.padding-left-0 {
  [dir=ltr] & {
    padding-left: 0;
  }
  [dir=rtl] & {
    padding-right: 0;
  }
}
.padding-right-1 {
  [dir=ltr] & {
    padding-right: 5px;
  }
  [dir=rtl] & {
    padding-left: 5px;
  }
}
</style>
